import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import CallBackLogin from '../views/CallBackLogin.vue'
import DrProjectRegister from "../views/drProjectManager/drProjectRegister.vue";
import registrationPage from "../views/registrationPage.vue";
import NoPage from '../views/404'
import Layout from "../views/Layout/Layout";
import Router from 'vue-router'
import WordTable from '../views/drProjectManager/wordTable.vue'
import resetPage from '../views/resetPage.vue'

Vue.use(VueRouter)
// const originalPush = VueRouter.prototype.push;
// VueRouter.prototype.push = function push(location) {
//     return originalPush.call(this, location).catch((err) => err);
// };
const createRouter = () => new Router({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/404', component: NoPage, name: 'NoPage',
            meta: {
                title: 'NoPage',
                requireAuth: false,
                NoTabPage: true,
                NoNeedHome: true // 添加该字段，表示不需要home模板
            },
            hidden: true
        },
        {
            path: '/',
            component: DrProjectRegister,
            name: '主页面',
            iconCls: 'fa-qq',//图标样式class
            // hidden: true,
            meta: {
                title: '主页面',
                requireAuth: true // 添加该字段，表示进入这个路由是需要登录的
            }
        },
        {
            path: '/login',
            component: Login,
            name: 'login',
            iconCls: 'fa-address-card',//图标样式class
            meta: {
                title: '登录',
                NoTabPage: true,
                NoNeedHome: true // 添加该字段，表示不需要home模板
            },
            hidden: true
        },
        {
            path: '/callBackLogin',
            component: CallBackLogin,
            name: 'callBackLogin',
            iconCls: 'fa-address-card',//图标样式class
            meta: {
                title: '修改密码',
                NoTabPage: true,
                NoNeedHome: true // 添加该字段，表示不需要home模板
            },
            hidden: true
        },
        {
            path: '/wordTable',
            component: WordTable,
            name: '项目登记 / 完成项目登记',
            iconCls: 'fa-address-card',
            meta: {
                title: '完成项目登记',
                requireAuth: true // 添加该字段，表示进入这个路由是需要登录的
            }
        },
        {
            path: '/registrationPage',
            component: registrationPage,
            name: '注册',
            iconCls: 'fa-address-card',
            meta: {
                title: '注册成功',
                requireAuth: false //
            }
        },
        {
            path: '/resetPage',
            component: resetPage,
            name: '重置密码',
            iconCls: 'fa-address-card',
            meta: {
                title: '重置成功',
                requireAuth: false
            }
        },
    ]
})
const router = createRouter()


export default router

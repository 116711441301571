export function isValidContactNumber (rule, value, callback) {
  // 使用正则表达式验证
  const reg = /^(0(\.\d{1,2})?|1(\.\d{1,2})?|[0-9]\d*(\.\d{1,2})?)$/.test(value);
  if (!reg) {
    return callback(new Error("请输入大于等于0的有效数字，小数点后只保留两位有效数字"));
  }

  callback();
}

export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

export function validURL(url) {
  const reg = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/
  return reg.test(url)
}

export function validEmail(email) {
  const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return reg.test(email)
}

// 手机号正则验证
export function validPhone(phone) {
  // const reg = /^(13[0-9]|14[01456879]|15[0-3,5-9]|16[2567]|17[0-8]|18[0-9]|19[0-3,5-9])\d{8}$/  //手机号
  const reg = /^((0\d{2,3}(-)?\d{7,8})|(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8})$/  //固话+手机号
  return reg.test(phone)
}

//验证密码
export function validPassword(password) {
  const reg = /^(?=.*[a-zA-Z])(?=.*[0-9]).{8,}$/
  return reg.test(password)
}

// 身份证号验证正则
export function valididentityCard(identityCard) {
  const reg = /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
  return reg.test(identityCard)
}

export function isString(str) {
  if (typeof str === 'string' || str instanceof String) {
    return true
  }
  return false
}

export function isArray(arg) {
  if (typeof Array.isArray === 'undefined') {
    return Object.prototype.toString.call(arg) === '[object Array]'
  }
  return Array.isArray(arg)
}

export function isDigit(rule, value, callback) {
  const reg = /^\d+$/;
  let val = parseInt(value);
  if(!reg.test(val)) {
    return callback(new Error("请输入数字"));
  }
  if(val === 0) {
    return callback(new Error("请输入天数大于0"));
  }
  callback();
}

export function validString (rule, value, callback) {
  const reg = /[^\/?*^]+/;
  let val = value;
  if (!reg.test(val)) {
    return callback(new Error("输入包含非法字符'/?^*'"));
  }
  callback();
}

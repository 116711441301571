<template xmlns="http://www.w3.org/1999/html">
	<div style="padding: 10px 20px">
		【生效时间】：2024年8月1日<br>
		【更新时间】：2024年8月1日<br>
		<p style="text-indent: 2em;">
			“消防安全检查AI人工智能图审系统”（以下称“本系统”）是由清大东方智能科技有限公司（以下称“我们”）提供的产品和服务。请您仔细阅读、充分理解以下全部内容，特别是其中限制或免除责任的条款应重点注意。
		</p>
		<p style="text-indent: 2em;font-weight: bold">
			【特别提示】请您使用本系统前，仔细阅读本《消防安全检查AI人工智能图审系统用户协议》（下称“本协议”）条款，在确认充分理解后再开始使用。您有权同意或者不同意本协议。如果您不同意本协议，请停止使用本系统。如果您继续注册使用本系统，即视为您已阅读并同意本协议的约束，包括同意接受我们对本协议服务条款随时做的任何修改。
		</p>
		<p style="font-weight: bold">
			第一条 服务说明
		</p>
		<p style="text-indent: 2em;">
			1．本协议是由清大东方智能科技有限公司与用户就使用本系统和服务所做的权利义务约定。
		</p>
		<p style="text-indent: 2em;">
			2．本系统应用于消防安全检查业务的AI人工智能图审领域，提供的服务主要包括图纸上传、AI审图、生成审查意见等核心功能和用户注册、资料管理等辅助功能。
		</p>
		<p style="text-indent: 2em;">
			3．本系统仅提供相关服务，除此之外与本服务有关的设备(如电脑、调制解调嚣及其他与接入互联网有关的装置)及所需的费用(如为接入互联网而支付的电话费及上网费)均应由用户自行负担。
		</p>
		<p style="font-weight: bold">
			第二条 用户账户的注册、使用与注销
		</p>
		<p style="text-indent: 2em;">
			1．本系统重视未成年人的安全与个人信息保护，注册本系统的用户应是具备完全民事行为能力和民事权利能力的自然人。用户一旦注册成功，我们将推定您具有相应的民事行为能力。若您不具备前述与您行为相适应的民事行为能力，则您及您的监护人应依照法律规定承担因使用本系统而产生的一切法律后果。
		</p>
		<p style="text-indent: 2em;">
			2．用户应提供本人正在合法有效使用的手机号码进行本系统账号注册，并且您同意本系统将您手机号码作为用户身份识别信息。该号码是我们与您联系的重要渠道，如发生变更，应及时通知我们。否则，会因无法有效联系到您，或无法及时处理与您相关的争议事项而影响您的相关权益。
		</p>
		<p style="text-indent: 2em;">
			3．用户注册账号所提供和使用的信息应当真实、有效、合法。注册成功后，本系统将为您配置账户，该账户之所有权本系统所有，您在本协议有效期内仅享有使用权。由于账户关联用户的个人信息及本系统商业信息，该账户仅限您本人使用，未经本系统书面同意，您不得出借、出租或作其它形式的转让。
		</p>
		<p style="text-indent: 2em;">
			4．本系统有权根据法律法规或政策规定，或根据自行制定的规范或规则，对违规、不当使用账户行为予以限制或予以注销，如本系统判断您的账户涉及信息泄漏及/或影响本系统信息安全或者正常运营的及/或侵害或影响其他用户权益的，本系统可拒绝向您提供服务或终止本协议。
		</p>
		<p style="text-indent: 2em;">
			5．用户可以申请注销已注册的账户。如果您需要注销您的帐户，我们将在核实您的身份、要求您完成未完成的交易、完成资金结算（已充值或已获得的虚拟资产）后，为您提供帐户注销服务。在账户注销完成后，本系统不在为您提供任何服务。账户注销后，您的个人信息我们会使其在前台系统保持不可检索、访问的状态，或对其进行匿名化处理。您知晓，根据法律法规规定，相关交易信息保存时间应自交易完成之日起不少于三年。
		</p>
		<p style="text-indent: 2em;">
			6．为了更好的服务用户和保障用户账户安全，根据服务器运行及监测情况，本系统可对被系统判定为长时间未登录、登录异常高风险账号启动保护机制。
		</p>
		<p style="text-indent: 2em;">
			7．为使您更好地使用本系统的各项服务，并保障您的账户安全，如涉及第三方支付、资金结算或其他需要您提供银行卡信息的业务，您应当保证提供的银行帐户信息或第三方支付帐户信息真实、有效、合法，且属于您本人所有，我们及关联公司或我们合作的第三方支付机构将根据您提供的银行帐户信息或第三方支付帐户信息与您进行资金结算。您提供的银行帐户信息或第三方支付帐户信息错误或您拒绝提供，您应当理解您将无法使用本系统部分功能、第三方支付机构提供的支付功能以及您将无法获得结算资金。您理解并同意如因您未能提供有效的上述信息导致资金无法结算时，该部分资金不会因为未支付给您而产生利息。我们可以向您发送通知以便于您可以及时提供信息，若超出一定时间未得到回复，我们有权收回该部分结算资金自行处理。 我们将按照相关法律法规规定要求您完成实名认证。如涉及第三方支付、资金结算或其他需要您提供银行卡信息的业务，您应当保证提供的银行帐户信息或第三方支付帐户信息真实、有效、合法，且属于您本人所有，我们及关联公司或我们合作的第三方支付机构将根据您提供的银行帐户信息或第三方支付帐户信息与您进行资金结算。您提供的银行帐户信息或第三方支付帐户信息错误或您拒绝提供，您应当理解您将无法使用本APP部分功能、第三方支付机构提供的支付功能以及您将无法获得结算资金。您理解并同意如因您未能提供有效的上述信息导致资金无法结算时，该部分资金不会因为未支付给您而产生利息。我们可以向您发送通知以便于您可以及时提供信息，若超出一定时间未得到回复，我们有权收回该部分结算资金自行处理。
		</p>
		<p style="text-indent: 2em;">
			8．如发现任何未经您授权使用您的账户登录本系统的情形，我们建议您第一时间与我们联系，但您应理解本系统对任何请求采取行动均需要合理时间，且本系统所采取的行动无法避免或者阻止侵害结果的形成或者扩大，除本系统存在法定过错外，本系统不对该侵害结果及扩大承担任何责任。
		</p>
		<p style="font-weight: bold">
			第三条 用户个人信息收集、使用与保护
		</p>
		<p style="text-indent: 2em;">
			1．我们非常重视个人信息的收集、使用与保护，详见《消防安全检查AI人工智能图审系统隐私政策》。
		</p>
		<p style="text-indent: 2em;">
			2．《消防安全检查AI人工智能图审系统隐私政策》与本协议共同约束用户合法使用本系统。
		</p>
		<p style="text-indent: 2em">
			3．本系统接入第三方SDK初衷是为了向用户提供更成熟、优质的平台技术服务，有关服务会涉及到个人信息收集，在收集用户信息之前，本系统会征求您的授权同意。您的明示授权或继续使用，将视为您同意信息的合理采集以及相关的隐私协议。
		</p>
		<p style="font-weight: bold">
			第四条 本系统使用规范
		</p>
		<p style="text-indent: 2em;">
			1．用户在注册、使用本系统过程中制作、复制、发布、上传、传播的任何形式的内容，包括但不限于账号头像、昵称、工作单位、标签、地址注册信息及认证资料，或以文字、语音、图片、视频、图文任何形式发送、回复或自动回复的消息、相关链接页面以及其他使用账号或本服务所产生的内容均不得违反现行法律、法规、规章、政策规定。
		</p>
		<p style="text-indent: 2em;">
			2．如果用户在境外注册、使用本系统，应同时遵守所在地或相关国家（地区）的法律法规。
		</p>
		<p style="text-indent: 2em;">
			3．用户不得利用本系统账号或提供的服务以任何形式制作、复制、发布、上传、传播如下法律、法规和政策禁止的内容：
		</p>
		<p style="text-indent: 3em;">
			(1) 反对宪法所确定的基本原则的；
		</p>
		<p style="text-indent: 3em;">
			(2) 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；
		</p>
		<p style="text-indent: 3em;">
			(3) 损害国家荣誉和利益的；
		</p>
		<p style="text-indent: 3em;">
			(4) 煽动民族仇恨、民族歧视，破坏民族团结的；
		</p>
		<p style="text-indent: 3em;">
			(5) 破坏国家宗教政策，宣扬邪教和封建迷信的；
		</p>
		<p style="text-indent: 3em;">
			(6) 散布谣言，扰乱社会秩序，破坏社会稳定的；
		</p>
		<p style="text-indent: 3em;">
			(7) 散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；
		</p>
		<p style="text-indent: 3em;">
			(8) 侮辱或者诽谤他人，侵害他人合法权益的；
		</p>
		<p style="text-indent: 3em;">
			(9) 含有法律、行政法规禁止的其他内容的。
		</p>
		<p style="text-indent: 2em;">
			4.用户不得利用本系统账号或服务以任何形式制作、复制、发布、上传、传播如下干扰本系统正常运营，以及侵犯其他用户或第三方合法权益的内容：
		</p>
		<p style="text-indent: 3em;">
			(1) 含有任何性或性暗示的；
		</p>
		<p style="text-indent: 3em;">
			(2) 含有辱骂、恐吓、威胁内容的；
		</p>
		<p style="text-indent: 3em;">
			(3) 含有骚扰、垃圾广告、恶意信息、诱骗信息的；
		</p>
		<p style="text-indent: 3em;">
			(4) 涉及他人隐私、个人信息或资料的；
		</p>
		<p style="text-indent: 3em;">
			(5) 侵害他人名誉权、肖像权、知识产权、商业秘密合法权利的；
		</p>
		<p style="text-indent: 3em;">
			(6) 含有其他干扰本服务正常运营和侵犯其他用户或第三方合法权益内容的信息。
		</p>
		<p style="text-indent: 2em;">
			5．用户不得利用“本系统”账号或服务进行如下行为：
		</p>
		<p style="text-indent: 3em;">
			(1) 提交、发布虚假信息，或盗用他人头像或资料，冒充、利用他人名义的；
		</p>
		<p style="text-indent: 3em;">
			(2) 虚构事实、隐瞒真相以误导、欺骗他人的；
		</p>
		<p style="text-indent: 3em;">
			(3) 利用技术手段批量建立虚假账号的；
		</p>
		<p style="text-indent: 3em;">
			(4) 利用本系统账号或服务从事任何违法犯罪活动的；
		</p>
		<p style="text-indent: 3em;">
			(5) 制作、发布与以上行为相关的方法、工具，或对此类方法、工具进行运营或传播，无论这些行为是否为商业目的；
		</p>
		<p style="text-indent: 3em;">
			(6) 其他违反法律法规规定、侵犯其他用户合法权益、干扰本系统正常运营或本系统未明示授权的行为。
		</p>
		<p style="text-indent: 2em;">
			6．用户不得利用或针对本系统及相关服务进行任何危害计算机网络安全、危害社会经济秩序的相关行为，如果我们有理由认为您的行为违反或违反上述约定的，我们可独立进行判断并处理，且在任何时候有权在不事先通知的情况下终止向您提供服务，并依法追究相关责任。
		</p>
		<p style="text-indent: 2em;">
			7．用户不得以本系统的名义传送或发布信息；也不得以易被公众或第三方混淆为本系统官方意见的方式传送或发布信息。用户在本系统传送、发布的任何内容并不反映或代表，也不得被视为反映或代表本系统的观点、立场；用户须保证其通过本系统账号或服务所传送的信息真实、合法、无害、准确、有效。如因用户传送的信息违法、给本系统或第三方造成损害的，用户应当依法予以赔偿，本系统有权依据法律法规、政策、本协议条款对相关违法、错误信息作出的处理。
		</p>
		<p style="text-indent: 2em;">
			8．未经本系统书面许可，任何人不得以包括通过机器人、蜘蛛程序或设备监视、复制、传播、展示、镜像、上载、下载方式直接或间接盗取本系统及相关服务的视频、图文信息内容，或以任何方式（包括但不限于隐藏或者修改域名、平台特有标识、用户名）删除或改变相关信息内容的权利管理电子信息。本系统文字及/或标识，以及其他标识、徽记、产品和服务名称均为本系统所有，如有宣传、展示任何使用需要，您必须取得本系统事先书面授权。
		</p>
		<p style="font-weight: bold">
			第五条 违规用户的处理
		</p>
		<p style="text-indent: 2em;">
			1．如果本系统发现用户有违反法律法规、政策规定、管理规定、本协议条款约定，或被其他用户投诉具有违反法律法规、政策规定、本协议条款约定的行为，本系统有权依据法律法规、政策及本协议条款对用户的违法、错误的行为或内容进行处理，并视情节轻重对违规的用户处以包括但不限于警告、账号封禁、功能封禁、账户冻结、不予结算资金处罚，并将有关违法信息向有关部门报告，用户应承担由此产生的一切法律后果。
		</p>
		<p style="text-indent: 2em;">
			2．本系统对上述违规内容的违规内容的审查仅为初步表面审查，不对审查结论承担责任；如果用户对处理结果存在异议，则应提供相应的证明文件，并与我们或投诉方沟通或采取法律途径解决争议。
		</p>
		<p style="text-indent: 2em;">
			3．因用户违反法律法规、政策规定、本协议约定或发生其它过错，导致任何第三方向本系统或其合作方、关联方主张任何索赔、要求的，用户应承担相关责任。如果因此造成本系统或其合作方、关联方承担相关责任或发生其他任何损失的，包括律师费、调查取证费、电子数据公证费、差旅费，本系统或其合作方、关联方有权要求该用户承担赔偿责任或者向该用户追偿。
		</p>
		<p style="text-indent: 2em;">
			4．被处理用户如对投诉内容或处理决定有异议，可提交申诉意见，本系统将对申诉进行审查，并自行合理判断决定是否变更处罚措施。对于因投诉所导致的处理，本系统仍有权根据投诉方与用户共同确定的意见或司法机关生效法律文书增加、变更或撤销对用户的处理。
		</p>
		<p style="font-weight: bold">
			第六条 本协议的生效、变更与终止
		</p>
		<p style="text-indent: 2em;">
			1．如果您不同意本协议，请停止使用本系统。如果您继续使用本系统，即视为您已阅读并同意本协议的约束，包括同意接受我们对本协议服务条款随时做的任何修改。
		</p>
		<p style="text-indent: 2em;">
			2．我们会对本系统或服务进行升级优化，您理解并同意我们会根据商业需要，在符合法律规定的情况下对本协议的部分条款进行更新，更新后的协议条款将代替原来的协议并在正式发布之日起生效，您可以在相关服务页面查阅最新版本的协议条款。
		</p>
		<p style="text-indent: 2em;">
			3．如本协议后续发生变更，我们会在系统中公布更新后的协议，该公布行为视为我们已通知您更新内容。我们也会采用本协议约定的其他通知方式通知您。如您对更新后的用户协议有任何疑问，您可通过本协议提及的联系邮箱向本系统工作人员反馈，我们会及时为您解答。如您不同意更新后的协议，您可以停止使用本系统；如果您继续使用本系统，即视为您已接受修改后的协议。
		</p>
		<p style="text-indent: 2em;">
			4．存在以下任一情形的，本协议在用户与本系统之间终止。因终止本协议给对方或其他第三方合法权益造成损失的，我们有权要求您对造成的损失承担法律责任：
		</p>
		<p style="text-indent: 3em;">
			（1）用户账号注销的，本协议自注销之日起在用户和本系统之间终止；
		</p>
		<p style="text-indent: 3em;">
			（2）因违反法律法规、政策规定或本协议约定导致本协议终止的；
		</p>
		<p style="text-indent: 3em;">
			（3）违反本协议约定、发生其他违法或不当行为导致本协议终止的；
		</p>
		<p style="text-indent: 3em;">
			（4）发生其他导致本协议终止的情形。
		</p>
		<p style="text-indent: 2em;">
			5．本协议终止时，本系统有权要求您就您账户下未完成的交易、未支付的订单、未结算的资金进行处理，若您在合理时间内未处理，本系统有权以合理的方式代为处理，并就处理过程中带来损失的向您追偿。
		</p>
		<p style="font-weight: bold">
			第七条 不可抗力条款
		</p>
		<p style="text-indent: 2em;">
			1． “不可抗力”是指本协议双方不能合理控制、不可预见或即使预见亦无法避免的事件，该事件妨碍、影响或延误任何一方根据本协议履行其全部或部分义务。该事件包括但不限于政府行为、自然灾害、战争或任何其他类似事件。
		</p>
		<p style="text-indent: 2em;">
			2．遭受不可抗力事件的一方可暂行中止履行本合同项下的义务直至不可抗力事件的影响消除为止并且无需为此而承担违约责任，但应尽最大努力克服该事件，减轻负面影响。
		</p>
		
		<p style="font-weight: bold">
			第八条 争议解决条款
		</p>
		<p style="text-indent: 2em;">
			本协议的效力、解释及纠纷的解决，适用于中华人民共和国法律。若用户和本系统之间发生任何纠纷或争议，首先应友好协商解决，协商不成的，用户同意将纠纷或争议提交本系统所有者清大东方智能科技有限公司住所地有管辖权的人民法院诉讼。
		</p>
		<p style="font-weight: bold">
			第九条 其他条款
		</p>
		<p style="text-indent: 2em;">
			1．除非我们另行声明，本系统的所有产品、技术、软件、程序、数据及其他信息（包括文字、图标、图片、照片、音频、视频、图表、色彩组合、版面设计）的所有权利（包括版权、商标权、专利权、商业秘密及其他相关权利）均归本系统所有。未经我们书面许可，任何单位和个人不得私自复制、传播、展示、镜像、上载、下载、使用，或者从事任何其他侵犯本系统知识产权的行为。
		</p>
		<p style="text-indent: 2em;">
			2．本协议的任何条款不论因何种原因无效或不具可执行性，不影响其余条款在用户与本系统双方之间的法律效力。
		</p>
		<p style="text-indent: 2em;">
			3．如您对本协议条款有任何疑问或者需要帮助，可通过本系统客服联系方式与我们联系。
		</p>
	</div>
</template>

<script>


export default {}
</script>

<style lang="stylus" scoped>
</style>

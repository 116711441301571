import router from '@/router'
import { resetRouter, filterAsyncRouter } from '@/router/index'

import { getNavigationBar, saveRefreshtime } from '@/api/api';
import store from "@/store";






function routerGo(to, next) {
    resetRouter()
    //将路由数据传递给全局变量，做侧边栏菜单渲染工作
    global.antRouter = getRouter
    next({ ...to, replace: true })
}

//localStorage 存储数组对象的方法
function saveObjArr(name, data) {
    localStorage.setItem(name, JSON.stringify(data))
}

//localStorage 获取数组对象的方法
function getObjArr(name) {
    return JSON.parse(window.localStorage.getItem(name));
}




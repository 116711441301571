<template xmlns="http://www.w3.org/1999/html">
	<div style="padding: 10px 20px">
		【生效时间】：2024年8月1日<br>
		【更新时间】：2024年8月1日<br>
		<p style="text-indent: 2em;">
			“消防安全检查AI人工智能图审系统”（以下称“本系统”）是由清大东方智能科技有限公司（以下称“我们”）提供的产品和服务。我们深知保护个人信息的重要性，并庄严承诺保护使用本系统之用户（以下统称“用户”或“您”）的个人信息和隐私安全。您在使用本系统时，我们可能会收集和使用您的相关个人信息。我们希望通过本《消防安全检查AI人工智能图审系统隐私政策》（以下简称“本政策”）向您说明我们在收集和使用您相关个人信息时对应的处理规则等相关事宜，以便更好地保障您的权益。
		</p>
		<p style="font-weight: bold;text-indent: 2em;">
			【特别提示】请您在使用本系统前，仔细阅读本《消防安全检查AI人工智能图审系统隐私政策》（下称“本政策”）条款，在确认充分理解后再开始使用。您有权同意或者不同意本协议。如果您不同意本协议，请停止使用本系统。如果您继续使用本系统的服务即视为您已阅读并同意本协议的约束，包括同意接受我们对本协议服务条款随时做的任何修改。
		</p>
		<p style="font-weight: bold;">
			一、隐私保护
		</p>
		<p style="text-indent: 2em;">
			1．个人信息包括用户个人隐私信息和非个人隐私信息。个人隐私信息指用户的真实姓名、身份证号、手机号码、IP地址、工作单位等信息；非个人隐私信息指用户对本服务的操作状态以及使用习惯等明确且客观反映在本系统服务器端的基本记录信息、个人隐私信息范围外的其它普通信息，以及用户同意公开的上述隐私信息。
		</p>
		<p style="text-indent: 2em;">
			2．尊重用户个人信息的私有性是本系统的一贯制度，本系统采取技术措施和其他必要措施，确保用户个人信息安全，防止在本服务中收集的用户个人信息泄露、毁损或丢失。在发生前述情形或者本系统发现存在发生前述情形的可能时，将及时采取补救措施。 
		</p>
		<p style="text-indent: 2em;">
			3．本系统承诺未经您的同意不向任何第三方公开、透露个人以下特定情形除外：
		</p>
		<p style="text-indent: 3em;">
			（1）本系统根据法律法规规定或有权机关的指示提供用户的个人信息；
		</p>
		<p style="text-indent: 3em;">
			（2）由于用户将其账号密码告知他人或与他人共享帐户密码，由此导致的任何个人信息的泄漏，或其他非本系统原因导致的个人信息的泄露；
		</p>
		<p style="text-indent: 3em;">
			（3）用户自行选择向第三方公开其个人信息；
		</p>
		<p style="text-indent: 3em;">
			（4）用户与本系统及合作单位之间就个人信息的使用公开达成约定，本系统因此向合作单位公开用户个人信息；
		</p>
		<p style="text-indent: 3em;">
			（5）任何由于黑客攻击、电脑病毒侵入及其他不可抗力事件导致用户个人信息的泄露；
		</p>
		<p style="text-indent: 3em;">
			（6）本协议约定的其他情形等。
		</p>
		<p style="text-indent: 2em;">
			4．用户同意本系统可在以下事项中使用用户的个人信息：
		</p>
		<p style="text-indent: 3em;">
			（1）本系统向用户及时发送通知，如软件更新、本协议条款的变更等；
		</p>
		<p style="text-indent: 3em;">
			（2）本系统内部进行审计、数据分析和研究等，以改进本系统的产品、服务和与用户之间的沟通；
		</p>
		<p style="text-indent: 3em;">
			（3）根据本协议约定，用户公开其个人信息、后台管理、审查用户信息及进行处理措施；
		</p>
		<p style="text-indent: 3em;">
			(4)适用法律法规规定的其他事项。
		</p>
		<p style="text-indent: 2em;">
			除上述事顶外，如未取得用户同意，本系统不会将用户个人隐私信息使用于任何其他用途。
		</p>
		<p style="text-indent: 2em;">
			5．为了改善本系统的技术和服务，向用户提供更好的服务体验，本系统或可会自行收集使用或向第三方提供用户的非个人隐私信息。
		</p>
		<p style="font-weight: bold;">
			二、信息的使用
		</p>
		<p style="text-indent: 2em;">
			收集您的信息是为了向您提供服务。为了实现这一目的，本系统会把您的信息用于下列用途：
		</p>
		<p style="text-indent: 3em;">
			1．向您提供您使用的各项服务，并维护、改进这些服务。
		</p>
		<p style="text-indent: 3em;">
			2．本系统可能使用您提供的个人信息中的联系方式与您通信，例如通知您有关您的账户、安全性更新、产品和服务信息。为了更好的为您提供服务，请您及时查看本系统发送的相关信息。
		</p>
		<p style="text-indent: 3em;">
			3．如果您对本系统的内容不感兴趣，请及时回复取消内容的通知。
		</p>
		<p style="text-indent: 3em;">
			4．本系统可能使用您的个人信息以预防、发现、调查欺诈、危害安全、非法或违反与本系统或其关联方协议、政策或规则的行为，以保护您、其他本系统用户，或本系统或其关联方的合法权益。
		</p>
		<p style="text-indent: 3em;">
			5．本系统可能会将来自某项服务的个人信息与来自其他服务的信息结合起来，用于为了给您提供更加的服务使用，例如让您拥有更广泛的社交圈的需要而使用、共享或披露。
		</p>
		<p style="text-indent: 3em;">
			6．本系统不会向任何无关第三方提供、出售、出租、分享或交易您的个人信息，除非事先得到您的同意，或该第三方和本系统及其关联方单独或共同为您提供服务，且在该服务结束后，其将被禁止访问包括其以前能够访问的所有这些资料。您知悉并认可：本系统通过广告或其他方式向您提供链接，使您可以接入第三方服务或网站。您使用该等第三方的服务时，须受该第三方的服务条款及隐私条款约束，您需要仔细阅读其条款。本协议仅适用于本系统提供的服务嚣所收集的信息，并不适用于任何第三方提供的服务或第三方的信息使用规则，本系统对任何第三方使用由您提供的信息不承担任何责任。
		</p>
		<p style="font-weight: bold;">
			四、用户注册
		</p>
		<p style="text-indent:2em">
			1．您确认，在您进行注册及使用本系统服务时，您应当是具备相应民事行为能力的自然人、法人或其他组织。若您不具备前述主体资格，则您及您的家长或法定监护人(以下统称“监护人”)应承担因此而导致的一切后果，且本系统有权注销您的账户，并向您及您的监护人索偿。 
		</p>
		<p style="text-indent:2em">
			2．本系统非常重视对青少年个人信息搜集和使用的安全性的保护。本系统不接受未满18周岁的未成年人注册和使用，监护人应承担未成年人网络活动风险及保护未成年人相关网络隐私的首要责任。 
		</p>
		<p style="text-indent:2em">
			3．如发现您的用户账号中含有不雅文字或不恰当名称的，本系统有权注销您的账户。请勿以党和国家领导人或其他社会名人真实姓名、字号、艺名、笔名注册；请勿以国家机构或其他机构名称注册、请勿注册不文明、不健康的名称，及包含歧视、侮辱、猥亵类词语的名称；请勿注册易产生歧义、引起他人误解或其他不符合法律规定的账号；用户自己有义务保证账号和密码的安全，用户利用该账号和密码进行的一切活动引起的任何损失或损害，由用户自行承担全部责任，本平台不承担任何责任。
		</p>
		<p style="text-indent:2em">
			4．您了解并同意，一经注册用户账号，即视为您同意本系统及/或其关联公司可以通过拨打电话、发送短信或者电子邮件等方式向您注册时填写的手机号码、电子邮箱等发送、告知相应的产品服务广告信息、促销优惠等营销信息。您如果不同意接收相关信息，您可以通过客服或相关提示进行退订。
		</p>
		<p style="text-indent: 2em;">
			5．如您发现帐号遭到未授权的使用或发生其他任何安全问题，应该立即修改帐号密码并妥善保管。如有必要，请及时通知我们。因黑客行为或用户的保管疏忽导致账号非法使用，本系统不承担任何责任。
		</p>
		<p style="font-weight: bold;">
			五、用户行为
		</p>
		<p style="text-indent: 2em;">
			用户同意将不会利用本系统进行任何违法或不正当的活动，包括但不限于下列行为：
		</p>
		<p style="text-indent: 3em;">
			1．以人任何方式危害他人的合法权益。
		</p>
		<p style="text-indent: 3em;">
			2．发布或以其它方式传送含有下列内容之一的信息：
		</p>
		<p style="text-indent: 3em;">
			（1）冒充其他任何人或机构，或以虚伪不实的方式陈述或谎称与任何人或机构有关；
		</p>
		<p style="text-indent: 3em;">
			（2）依据任何法律或合约或法定关系(例如由于雇佣关系和依据保密合约所得知或揭露之内部资料、专属及机密资料)知悉但无权发布的文档资料；
		</p>
		<p style="text-indent: 3em;">
			（3）将侵害他人著作权、专利权、商标权、商业秘密、或其它专属权利(以下简称“专属权利”)之内容加以发布或以其它方式传送；
		</p>
		<p style="text-indent: 3em;">
			（4）将任何广告信函、促销资料、“垃圾邮件”、“滥发信件”、“连锁信件”、“直销”或其它任何形式的劝诱资料加以发布、发送或以其它方式传送；
		</p>
		<p style="text-indent: 2em;">
			3．将设计目的在于干扰、破坏或限制任何计算机软件、硬件或通讯设备功能之计算机病毒或其它计算机代码、档案和程序之任何资料，加以发布、发送或以其它方式传送。
		</p>
		<p style="text-indent: 2em;">
			4．跟踪、人肉搜索或以其它方式骚扰他人。
		</p>
		<p style="text-indent: 2em;">
			5．截获、篡改、收集、储存或删除他人个人信息、站内邮件或其它数据资料，或将获知的此类资料用于任何非法或不正当目的。
		</p>
		<p style="text-indent: 2em;">
			您同意遵守中华人民共和国相关法律法规的所有规定，并对以任何方式通过您的帐号使用本系统服务的任何行为及其结果承担全部责任。如相关行为违反国家法律和法规的任何规定，有可能构成犯罪的，将被追究刑事责任，并由您承担全部法律责任。同时，如果有理由认为您的行为违反或可能违反国家法律和法规的任何规定，本系统可在任何时候不经任何事先通知终止向您提供服务。
		</p>
		<p style="font-weight: bold;">
			六、知识产权
		</p>
		<p style="text-indent: 2em;">
			本系统及其提供的服务知识产权属于清大东方智能科技有限公司。未经清大东方智能科技有限公司书面许可，任何单位和个人不得私自复制、传播、展示、镜像、上载、下载、使用，或者从事任何其他侵犯本系统知识产权的行为。否则，清大东方智能科技有限公司将追究相关法律责任。 
		</p>
		<p style="font-weight: bold;">
			七、修订和通知
		</p>
		<p style="text-indent: 2em;">
			为了给您提供更好的服务，我们可能会根据本产品的更新情况及法律法规的相关要求适时修改本政策的条款，该等修改构成本政策的一部分。我们会在本页面上公布对本政策所作的任何变更。对于重大变更，我们还会提供更为显著的通知（包括网站公告、推送通知、弹窗提示或其他方式），本政策所指的重大变更包括但不限于：
		</p>
		<p style="text-indent: 3em;">
			1．我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等。
		</p>
		<p style="text-indent: 3em;">
			2．我们在所有权结构方面发生重大变化。如业务调整、破产并购等引起的所有者变更等。
		</p>
		<p style="text-indent: 3em;">
			3．个人信息共享、转让或公开披露的主要对象发生重大变化。
		</p>
		<p style="text-indent: 3em;">
			4．您参与个人信息处理方面的权利及其行使方式发生重大变化。
		</p>
		<p style="text-indent: 3em;">
			5．其他重要的或可能严重影响您的个人权益的情况发生。
		</p>
		<p style="text-indent: 2em;">
			我们的任何修改都会将您的满意度置于首位。我们鼓励您在每次使用本产品时都查阅我们的隐私政策。
		</p>
		<p style="font-weight: bold;">
			八、联系我们
		</p>
		<p style="text-indent: 2em;">
			如您有关于个人信息安全的投诉和举报，或您对本政策、您的个人信息的相关事宜有任何问题、意见或建议，请与我们的个人信息保护负责人联系，可通过本系统客服联系方式与我们联系。
		</p>
		<p style="text-indent: 2em;">
			正常情况下，我们将在收到您的问题、意见或建议，并验证您的用户身份后的十五个工作日内予以受理并处理。如您不满意我们的处理，还可以向履行个人信息保护职责的部门进行投诉、举报。
		</p>
	</div>
</template>

<script>


export default {}
</script>

<style lang="stylus" scoped>
</style>
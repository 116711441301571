<template>
	<div class="wrapper">
<!--		<ul class="bg-bubbles">-->
<!--			<li v-for="n in 10" :key="n+'n'"></li>-->
<!--			<ol v-for="m in 5"  :key="m+'m'"></ol>-->
<!--		</ul>-->
		<div style="height: 15%;"></div>
		<el-form :model="ruleForm2" :rules="rules2" ref="ruleForm2" label-position="left" label-width="0px"
		         class="demo-ruleForm login-container">
			<h3 class="title">修改密码</h3>
			<!-- <el-form-item prop="checkPass">
					<el-input v-model="ruleForm2.checkPass" auto-complete="off" show-password placeholder="旧密码"></el-input>
			</el-form-item> -->
			<el-form-item prop="newCheckPass">
				<el-input v-model="ruleForm2.newCheckPass" auto-complete="off" show-password placeholder="新密码"></el-input>
			</el-form-item>
			<el-form-item prop="newCheckPassSure">
				<el-input v-model="ruleForm2.newCheckPassSure" auto-complete="off" show-password placeholder="确认新密码"></el-input>
			</el-form-item>
			<el-form-item prop="verificationCode">
				<el-input type="text" v-model="ruleForm2.verificationCode" style="width: 50%;" auto-complete="off" placeholder="验证码"></el-input>
				<el-button type="primary" :loading="logining1" style="margin-left: 10px;" @click.native.prevent="getVerCodeFun">
					{{getVerCode}}{{getVerCode1}}
				</el-button>
			</el-form-item>
			
			<el-form-item style="width:100%;">
				<el-button type="primary" style="width:100%;" @click.native.prevent="handleSubmit2" :loading="logining">
					{{loginStr}}
				</el-button>
			
			</el-form-item>
			<el-button @click="noChangePass" style="padding: 3px 0" type="text">放弃修改密码</el-button>
		</el-form>
	</div>
</template>

<script>
import {sendModifyPasswordSMSVerificationCode,modifyUserPassword} from '../api/api';
import {validPhone} from '../../util/validate';
import router from '@/router'
import util from '../../util/date'
import {resetRouter, filterAsyncRouter} from '@/router/index'
import Cookies from 'js-cookie'
import { valididpassword } from "../../util/validate";
export default {
	data() {
		//验证密码
		let isallowPassword = (rule, value, callback) => {
			if (!valididpassword(value)) {
				return callback(new Error("至少为8个字符，其中包含数字、特殊字符、大写字母和小写字母"));
			} else {
				callback();
			}
		};
		return {
			loginStr: '确认修改',
			getVerCode: '获取验证码',
			getVerCode1: '',
			logining: false,
			logining1: false,
			timer: null,
			ruleForm2: {
				account: '',
				checkPass: '',
				newCheckPass: '',
				newCheckPassSure: '',
				verificationCode: '',
			},
			rules2: {
				checkPass: [
					{required: true, message: '请输入旧密码', trigger: 'blur'},
				],
				newCheckPass: [
					{required: true, message: '请输入新密码', trigger: 'blur'},
					{ validator: isallowPassword },
				],
				newCheckPassSure: [
					{required: true, message: '请确认新密码', trigger: 'blur'},
					{ validator: isallowPassword },
				],
				verificationCode: [
					{required: true, message: '请确认验证码', trigger: 'blur'},
				],
			},
		};
		
	},
	methods:{
		// 修改密码
		handleSubmit2(ev) {
			var _this = this;
			this.$refs.ruleForm2.validate((valid) => {
				if (valid) {
					if(this.ruleForm2.newCheckPass === this.ruleForm2.newCheckPassSure){}else{
						this.$message({
							message: "请确保密码输入一致",
							type: 'error'
						});
						return false;
					}
					this.$confirm("请确认修改密码？", "提示", {}).then(() => {
								var params = {
									Code: this.ruleForm2.verificationCode,
									NewPasswordOne: this.ruleForm2.newCheckPass,
									NewPasswordTwo: this.ruleForm2.newCheckPassSure,
								}
								this.logining = true;
								this.loginStr = "正在修改"
								modifyUserPassword(params).then(res => {
									var result = res.data
									if(result.Success){
										this.$message({
											message: '修改密码成功，请重新登录！',
											type: 'success'
										});
										window.localStorage.removeItem("user");
										window.localStorage.removeItem("Token");
										window.localStorage.removeItem("TokenExpire");
										window.localStorage.removeItem("NavigationBar");
										window.localStorage.removeItem("refreshtime");
										window.localStorage.removeItem("router");
										sessionStorage.removeItem("Tags");
										
										window.location.reload();
										_this.$router.replace("/login");
									}else{
										this.$message({
											message: result.Message,
											type: 'error'
										});
										setTimeout(function(){
											_this.logining = false;
											_this.loginStr = "重新修改"
										},3000)
									}
								})
							})
							.catch(() => {
								// this.$router.replace(this.$route.query.redirect ? this.$route.query.redirect : "/");
							})
				} else {
					console.log('error submit!!');
					return false;
				}
			});
		},
		// 放弃修改密码
		noChangePass() {
			this.$confirm("确认放弃修改密码？", "提示", {}).then(() => {
						var remove = this.$route.query.isRemove
						if(remove == "true"){
							window.localStorage.removeItem("user");
							window.localStorage.removeItem("Token");
							window.localStorage.removeItem("TokenExpire");
							window.localStorage.removeItem("NavigationBar");
							window.localStorage.removeItem("refreshtime");
							window.localStorage.removeItem("router");
							window.localStorage.removeItem("expiresIn");
							sessionStorage.removeItem("Tags");
						}
						this.$router.replace(this.$route.query.redirect ? this.$route.query.redirect : "/");
					})
					.catch(() => {
					
					})
		},
		// 获取验证码
		getVerCodeFun() {
			this.$confirm("将要获取验证码", "提示", {}).then(() => {
						this.logining1 = true
						this.getVerCode1 = "秒后重新获取"
						this.sendPhone();
						sendModifyPasswordSMSVerificationCode().then(res => {
							var result = res.data
							if(result.Success){
								this.$message({
									message: '验证码发送成功！',
									type: 'success'
								});
							}else{
								this.$message({
									message: result.Message,
									type: 'error'
								});
							}
						})
						var nowTimer = new Date()
						sessionStorage.setItem("nowTimer",nowTimer)
					})
					.catch(() => {})
		},
		// 倒计时
		sendPhone(){
			const TIME_COUNT = sessionStorage.getItem("codeData") || 60; //  更改倒计时时间
			if (!this.timer) {
				this.getVerCode = TIME_COUNT;
				this.logining1 = true;
				this.timer = setInterval(() => {
					if (this.getVerCode > 0 && this.getVerCode <= TIME_COUNT) {
						this.getVerCode--;
						sessionStorage.setItem("codeData",this.getVerCode)
					} else {
						this.logining1 = false;
						this.getVerCode1 = ""
						this.getVerCode = "获取验证码"
						clearInterval(this.timer); // 清除定时器
						this.timer = null;
						sessionStorage.removeItem("codeData")
						sessionStorage.removeItem("nowTimer")
					}
				}, 1000);
			}
		},
	},
	created() {
		// 已经获取验证码后防止刷新页面数据丢失
		var nowTimer = sessionStorage.getItem("nowTimer")
		var timeData = sessionStorage.getItem("codeData")
		var newNowTimer = new Date()
		if(newNowTimer - nowTimer > 60) {
			if(timeData){
				this.logining1 = false
				this.getVerCode1 = "获取验证码"
			}
		} else {
			if(timeData){
				this.sendPhone()
				this.logining1 = true
				this.getVerCode1 = "秒后重新获取"
			}
		}
		var curTime = new Date();
		if (window.localStorage.TokenExpire) {
			var expiretime = new Date(Date.parse(window.localStorage.TokenExpire));
			if (curTime >= expiretime) {
				this.$router.push("/login");
			}
		} else {
			this.$router.push("/login");
		}
	}
}

</script>

<style >
.bg {
	margin: 0px;
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	background: url(../assets/loginbck.png) no-repeat top left;
	background-repeat: no-repeat;
	background-size: cover;
	width: 100%;
	height: 100%;
}

.login-container {
	-webkit-border-radius: 5px;
	border-radius: 5px;
	-moz-border-radius: 5px;
	background-clip: padding-box;
	margin: auto;
	width: 350px;
	padding: 35px 35px 15px 35px;
	background: #fff;
	border: 1px solid #eaeaea;
	box-shadow: 0 0 25px #cac6c6;
	z-index: 9999;
	position: relative;
}

.login-container .title {
	margin: 0px auto 40px auto;
	text-align: center;
	color: #505458;
}

.login-container .remember {
	margin: 0px 0px 25px 0px;
}

.wrapper {
	//background: #50a3a2;
	background: white;
	//background: -webkit-linear-gradient(top left, #50a3a2 0%, #53e3a6 100%);
	//background: linear-gradient(to bottom right, #127c7b 0, #50a3a2);
	//opacity: 0.8;
	position: absolute;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.wrapper.form-success .containerLogin h1 {
	-webkit-transform: translateY(85px);
	-ms-transform: translateY(85px);
	transform: translateY(85px);
}

.containerLogin {
	max-width: 600px;
	margin: 0 auto;
	padding: 80px 0;
	height: 400px;
	text-align: center;
}

.containerLogin h1 {
	font-size: 40px;
	-webkit-transition-duration: 1s;
	transition-duration: 1s;
	-webkit-transition-timing-function: ease-in-put;
	transition-timing-function: ease-in-put;
	font-weight: 200;
}

.bg-bubbles {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
}

.bg-bubbles li, .bg-bubbles ol {
	position: absolute;
	list-style: none;
	display: block;
	width: 40px;
	height: 40px;
	background-color: rgba(255, 255, 255, 0.15);
	bottom: -160px;
	-webkit-animation: square 25s infinite;
	animation: square 25s infinite;
	-webkit-transition-timing-function: linear;
	transition-timing-function: linear;
}

ol {
	padding: 0 !important;
}

.bg-bubbles ol:nth-child(11) {
	left: 10%;
	top: 10%;
	width: 20px;
	height: 20px;
}

.bg-bubbles ol:nth-child(12) {
	left: 20%;
	top: 40%;
	
	width: 60px;
	height: 60px;
}

.bg-bubbles ol:nth-child(13) {
	left: 65%;
	top: 30%;
	
	width: 100px;
	height: 60px;
}

.bg-bubbles ol:nth-child(14) {
	left: 70%;
	top: 30%;
	width: 100px;
	height: 150px;
}

.bg-bubbles ol:nth-child(15) {
	left: 50%;
	top: 70%;
	
	width: 40px;
	height: 60px;
}

.bg-bubbles li:nth-child(1) {
	left: 10%;
}

.bg-bubbles li:nth-child(2) {
	left: 20%;
	width: 80px;
	height: 80px;
	-webkit-animation-delay: 2s;
	animation-delay: 2s;
	-webkit-animation-duration: 17s;
	animation-duration: 17s;
}

.bg-bubbles li:nth-child(3) {
	left: 25%;
	-webkit-animation-delay: 4s;
	animation-delay: 4s;
}

.bg-bubbles li:nth-child(4) {
	left: 40%;
	width: 60px;
	height: 60px;
	-webkit-animation-duration: 22s;
	animation-duration: 22s;
	background-color: rgba(255, 255, 255, 0.25);
}

.bg-bubbles li:nth-child(5) {
	left: 70%;
}

.bg-bubbles li:nth-child(6) {
	left: 80%;
	width: 120px;
	height: 120px;
	-webkit-animation-delay: 3s;
	animation-delay: 3s;
	background-color: rgba(255, 255, 255, 0.2);
}

.bg-bubbles li:nth-child(7) {
	left: 32%;
	width: 160px;
	height: 160px;
	-webkit-animation-delay: 7s;
	animation-delay: 7s;
}

.bg-bubbles li:nth-child(8) {
	left: 55%;
	width: 20px;
	height: 20px;
	-webkit-animation-delay: 15s;
	animation-delay: 15s;
	-webkit-animation-duration: 40s;
	animation-duration: 40s;
}

.bg-bubbles li:nth-child(9) {
	left: 25%;
	width: 10px;
	height: 10px;
	-webkit-animation-delay: 2s;
	animation-delay: 2s;
	-webkit-animation-duration: 40s;
	animation-duration: 40s;
	background-color: rgba(255, 255, 255, 0.3);
}

.bg-bubbles li:nth-child(10) {
	left: 90%;
	width: 160px;
	height: 160px;
	-webkit-animation-delay: 11s;
	animation-delay: 11s;
}

@-webkit-keyframes square {
	0% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
	
	100% {
		-webkit-transform: translateY(-700px) rotate(600deg);
		transform: translateY(-700px) rotate(600deg);
	}
}

@keyframes square {
	0% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
	
	100% {
		-webkit-transform: translateY(-700px) rotate(600deg);
		transform: translateY(-700px) rotate(600deg);
	}
}

.content-az {
	padding: 0 !important;
	border: none !important;
}
</style>

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import 'font-awesome/css/font-awesome.min.css'
import '../util/globalStyle.css'

import './promissionRouter'//这里进行路由后台获取的模拟

Vue.config.productionTip = false;

// 模拟的认证检查函数
function isAuthenticated() {
  // 在这里检查用户是否已登录
  // 例如：检查本地存储中的token，或者调用某个API
  return !!localStorage.getItem('Token');
  
}

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requireAuth)) {
    if (!isAuthenticated()) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      });
    } else {
      next();
    }
  } else {
    next();
  }
  
});


Vue.use(ElementUI);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

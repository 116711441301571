<template>
	<div id="app">
		<!-- 页面层 -->
		<transition name="fade" mode="out-in">
			<div class="content-az">
				<router-view></router-view>
			</div>
		</transition>
	</div>
</template>
<script>
import Message from "./components/Message";
// import { getUserByToken } from "./api/api";
import router from './router/index'
export default {
	name: 'App',
	provide () {    //父组件中通过provide来提供变量，在子组件中通过inject来注入变量。
		return {
			reload: this.reload
		}
	},
	components: { Message },
	data() {
		return {
			isRouterAlive: true,  //控制视图是否显示的变量
			
			collapsed: false,
			zModalShadow: false,
			collapsedClass: "menu-expanded",
			ispc: false,
			sysUserName: "后台管理系统",
			newsDialogCss: "news-dialog",
			sysUserAvatar: "",
			isCollapse: false,
			tagsList: [],
			
			routes: [],
			unreadCount: 0,
			// tagNews: [
			// 	{ name: "首页", type: "" },
			// 	{ name: "用户管理", type: "" },
			// 	{ name: "组织机构管理", type: "success" },
			// 	{ name: "角色管理", type: "info" },
			// 	{ name: "菜单权限管理", type: "warning" },
			// 	{ name: "异常报告！", type: "danger" },
			// ],
			visible: false,
			top: 0,
			left: 0,
			selectedTag: {},
			affixTags: [],
			messageTab: "task",
			// 我的消息详情
			messageDetailDialog: false,
			chilNodesData: '',
		};
	},
	methods: {
		handleClick(tab, event) {
			console.log(this.messageTab);
		},
		reload() {
			this.isRouterAlive = false;            //先关闭，
			this.$nextTick(function () {
				this.isRouterAlive = true;         //再打开
			})
		},
		
		//退出登录
		logout: function () {
			var _this = this;
			this.$confirm("确认退出吗?", "提示", {
				//type: 'warning'
			}).then(() => {
						window.localStorage.removeItem("user");
						window.localStorage.removeItem("Token");
						window.localStorage.removeItem("TokenExpire");
						window.localStorage.removeItem("NavigationBar");
						window.localStorage.removeItem("refreshtime");
						window.localStorage.removeItem("router");
						sessionStorage.removeItem("Tags");
						global.antRouter = [];
						this.tagsList = [];
						this.routes = [];
						this.$store.commit("saveTagsData", "");
						_this.$router.push("/login");
						window.location.reload();
					})
					.catch(() => {
					});
		},
		
		
		
	},
	mounted() {
		var tags = sessionStorage.getItem("Tags")
		? JSON.parse(sessionStorage.getItem("Tags"))
		: [];
		if (tags && tags.length > 0) {
			this.tagsList = tags;
		}
		var NavigationBar = JSON.parse(
			window.localStorage.router ? window.localStorage.router : null
		);
		// var NavigationBar = global.antRouter;
		if (this.routes.length <= 0 && NavigationBar && NavigationBar.length >= 0) {
			this.routes = NavigationBar;
		}
		// 折叠菜单栏
		// this.collapse();
	},
	updated() {
		var user = JSON.parse(
			window.localStorage.user ? window.localStorage.user : null
		);
		if (user) {
			this.sysUserName = user.name+'-'+user.loginAccount || "后台管理系统";
			this.sysUserAvatar = user.avatar || "../assets/logo.png";
			this.getUnReadCount();
		}
		var NavigationBar = JSON.parse(
			window.localStorage.router ? window.localStorage.router : null
		);
		if (NavigationBar && NavigationBar.length >= 0) {
			if (
				this.routes.length <= 0 ||
				JSON.stringify(this.routes) != JSON.stringify(NavigationBar)
			) {
				this.routes = NavigationBar;
			}
		}
	},
	computed: {
		showTags() {
			if (this.tagsList.length > 1) {
				this.$store.commit("saveTagsData", JSON.stringify(this.tagsList));
			}
			return this.tagsList.length > 0;
		},
	},
	watch: {
		// 对router进行监听，每当访问router时，对tags的进行修改
		$route: async function (newValue, from) {
			// this.setTags(newValue);
			const tags = this.$refs.tag;
			this.$nextTick(() => {
				if (tags) {
					for (const tag of tags) {
						if (tag.to.path === this.$route.path) {
							// this.$refs.scrollPane.moveToTarget(tag, tags);
							// when query is different then update
							// if (tag.to.fullPath !== this.$route.fullPath) {
							//     this.$store.dispatch('tagsView/updateVisitedView', this.$route)
							// }
							break;
						}
					}
				}
			});
		},
	},
	created() {
		// 第一次进入页面时，修改tag的值
		// this.setTags(this.$route);
		this.ispc = window.screen.width > 680;
		if (this.ispc) {
			this.collapsedClass = "menu-expanded";
		} else {
			this.collapsedClass = "menu-expanded-mobile mobile-ex";
			this.collapsed = true;
			// this.collapse();
		}
	},
};
</script>

<style lang="css">
@import "./style/home.css";
.el-breadcrumb {
  	line-height: 60px !important;
}
</style>

<style lang="stylus" scoped>
    .myMessageDialog>>>.el-dialog {
        height: 100%!important;
    }
    .myMessageDialog>>>.el-dialog__body {
        height: 100%!important;
    }
	.messageDialogChil {
		height: 100%!important;
	}
    .el-tabs el-tabs--left {
        height: 100%!important;
    }
	.messageDetailDialog {
		z-index: 3918;
		position: relative;
		background: #fff;
		width: 450px;
		top: 30%;
		left: 30%;
		min-height: 100px;
		padding: 10px 10px 20px;
	}
	.closeDiaBtn {
		width: 24px;
		height: 24px;
		font-size: 20px;
		background: #fff;
		text-align: center;
		line-height: 20px;
		border-radius: 50%;
		position: absolute;
		right: 10px;
		cursor: pointer;
	}
</style>

<style>
.menu-collapsed .el-icon-arrow-right:before {
  	display: none;
}
.tags {
	position: relative;
	overflow: hidden;
	border: 1px solid #f0f0f0;
	background: #f0f0f0;
}
.tags ul {
	box-sizing: border-box;
	width: 100%;
	height: 100%;
	padding: 0;
	margin: 0;
	display: none;
}
.tags-li {
	float: left;
	margin: 3px 5px 2px 3px;
	border-radius: 3px;
	font-size: 13px;
	overflow: hidden;
	height: 23px;
	line-height: 23px;
	border: 1px solid #e9eaec;
	background: #fff;
	padding: 3px 5px 4px 12px;
	vertical-align: middle;
	color: #666;
	-webkit-transition: all 0.3s ease-in;
	transition: all 0.3s ease-in;
}
.tags-li-icon {
  	cursor: pointer;
}
.tags-li:not(.active):hover {
  	background: #f8f8f8;
}
.tags-li-title {
	float: left;
	max-width: 80px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	margin-right: 5px;
	color: #666;
	text-decoration: none;
}
.tags-close-box {
	box-sizing: border-box;
	text-align: center;
	z-index: 10;
	float: right;
	margin-right: 1px;
	line-height: 2;
}
</style>
<style>
.news-dialog {
	background: #fff;
	z-index: 3000 !important;
	position: fixed;
	height: 100vh;
	width: 100%;
	max-width: 800px;
	top: 60px !important;
	left: 0 !important;
	-webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
	box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
	-webkit-transition: all 0.25s cubic-bezier(0.7, 0.3, 0.1, 1);
	transition: all 0.25s cubic-bezier(0.7, 0.3, 0.1, 1);
	-webkit-transform: translate(100%);
	z-index: 40000;
	left: auto !important;
	right: 0 !important;
	transform: translate(0);
}
.news-dialog .el-dialog {
	margin: auto !important;
	-webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
	box-shadow: none;
	width: 100%;
}
.news-dialog.show {
  	transform: translate(0);
}
.tag-new {
	width: 100%;
	margin: 10px 0;
}
@media screen and (max-width: 680px) {
	.collapsedLogo {
		display: none;
	}
	.el-dialog {
		width: 90% !important;
	}
	.content-expanded {
		max-width: 100% !important;
		max-height: calc(100% - 60px);
	}
	.mobile-ex {
		background: #fff;
		z-index: 3000;
		position: fixed;
		height: 100vh;
		width: 100%;
		max-width: 260px;
		top: 0;
		left: 0;
		-webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
		box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
		-webkit-transition: all 0.25s cubic-bezier(0.7, 0.3, 0.1, 1);
		transition: all 0.25s cubic-bezier(0.7, 0.3, 0.1, 1);
		-webkit-transform: translate(100%);
		z-index: 40000;
		left: auto;
		right: 0;
		transform: translate(100%);
	}
	.mobile-ex.menu-collapsed-mobile {
		transform: translate(0);
	}
	.el-menu--collapse {
		width: 100% !important;
	}
	.el-date-editor.el-input,
	.el-date-editor.el-input__inner,
	.el-cascader.el-cascader--medium {
		width: 100% !important;
	}
	.toolbar.roles {
		width: 100% !important;
	}
	.toolbar.perms {
		width: 800px !important;
	}
	.toolbar.perms .box-card {
		width: 100% !important;
	}
	.login-container {
		width: 400px !important;
	}
	.content-wrapper .tags {
		margin: 0px;
		padding: 0px;
	}
	.activeuser {
		display: none !important;
	}
}
</style>

<style>
.tags-view-container {
	height: 34px;
	width: calc(100% - 60px);
	/*background: #fff;*/
	/*border-bottom: 1px solid #d8dce5;*/
	/*box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 0 3px 0 rgba(0, 0, 0, 0.04);*/
	float: left;
}
.tags-view-container .tags-view-wrapper .tags-view-item {
	display: inline-block;
	position: relative;
	cursor: pointer;
	height: 26px;
	line-height: 26px;
	border: 1px solid #d8dce5;
	color: #495060;
	background: #fff;
	padding: 0 8px;
	font-size: 12px;
	margin-left: 5px;
	margin-top: 4px;
}
.tags-view-container .tags-view-wrapper .tags-view-item:first-of-type {
  	margin-left: 15px;
}
.tags-view-container .tags-view-wrapper .tags-view-item:last-of-type {
  	margin-right: 15px;
}
.tags-view-container .tags-view-wrapper .tags-view-item.active::before {
	content: "";
	background: #2d8cf0;
	display: inline-block;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	position: relative;
	margin-right: 2px;
}
.tags-view-container .contextmenu {
	margin: 0;
	background: #fff;
	z-index: 3000;
	position: absolute;
	list-style-type: none;
	padding: 5px 0;
	border-radius: 4px;
	font-size: 12px;
	font-weight: 400;
	color: #333;
	box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);
}
.tags-view-container .contextmenu li {
	margin: 0;
	padding: 7px 16px;
	cursor: pointer;
}
.tags-view-container .contextmenu li:hover {
  	background: #eee;
}
</style>

<style>
.tags-view-wrapper .tags-view-item .el-icon-close {
	width: 16px;
	height: 16px;
	vertical-align: 2px;
	border-radius: 50%;
	text-align: center;
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transform-origin: 100% 50%;
}
.tags-view-wrapper .tags-view-item .el-icon-close:before {
	transform: scale(0.6);
	display: inline-block;
	vertical-align: -3px;
}
.tags-view-wrapper .tags-view-item .el-icon-close:hover {
	background-color: #ef2b74;
	color: #fff;
}
.logo-collapse-width div{
  	font-size: 18px!important;
}
.logo-width{
	display: flex;
	align-items: center;
}
</style>

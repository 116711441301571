<template>
  <div id="">
    <div class="headerContainer" style="font-size: 20px">
      <img src="../assets/logo.png" width="40px" alt="">
      <div style="display: inline-block;margin-left: 10px;font-weight: bold">消防安全检查-AI人工智能图审系统</div>
      <div > &nbsp;|&nbsp; 重置密码</div>
    </div>
    <div class="parent" style="font-size: 20px">
      <div class='child'>
        <div  class="" style="font-weight: bold;margin-left:32%;padding-bottom:8px ; ">
          重置密码
        </div>
      </div>
    </div>

    <div style="width: 80%;margin: 0 auto 15%;height: 50vh">
      <el-form :model="form" :rules="rules" ref="form" label-position="left" label-width="130px" class="">
        <el-form-item label="手机号" prop="phoneNumber">
          <el-input style="width: 50%" v-model="form.phoneNumber"
                    placeholder="请输入手机号，用于账号登录、找回密码、接收验证码"></el-input>
        </el-form-item>
<!--        <div style="display: flex;">-->
<!--          <el-form-item prop="QRcode" label="图形验证码">-->
<!--            <el-input v-model="form.QRcode" style="width: 200px;" @keyup.enter.native="enterKey" auto-complete="off"-->
<!--                      placeholder="请先验证图形验证码"></el-input>-->
<!--          </el-form-item>-->
<!--          <div v-loading="imageLoading">-->
<!--            <img :src="codeImage" @click="refreshCode" style="width: 150px;height: 40px;margin-left:20px;" alt="验证码">-->
<!--          </div>-->
<!--	        <el-button style='margin-left: 20px;height: 40px' type="primary" @click="sendVerificationCode" :disabled="countdown > 0">-->
<!--		        {{ countdown > 0 ? `${countdown}秒后重试` : '获取验证码' }}-->
<!--	        </el-button>-->
<!--        </div>-->
	      <el-form-item label="短信验证码" prop="code">
		      <div style="display: flex">
			      <el-input style="width: 39%" v-model="form.code" placeholder="请输入短信验证码"></el-input>
			      <el-button style='margin-left: 20px ;height: 40px' type="primary" size="medium" @click="yanZheng" :disabled="countdown > 0">
				      {{ countdown > 0 ? `${countdown}秒后重试` : '获取验证码' }}
			      </el-button>
		      </div>
	      </el-form-item>
<!--        <el-form-item label="姓名" prop="name">-->
<!--          <el-input style="width: 50%" v-model="form.name" placeholder="企业账号联系人姓名"></el-input>-->
<!--        </el-form-item>-->
        <el-form-item label="设置登录密码" prop="password">
          <el-input style="width: 50%" v-model="form.password" type="password" show-password
                    placeholder="8位以上数字+字母"></el-input>
        </el-form-item>
        <el-form-item label="再次输入密码" prop="confirmPassword">
          <el-input style="width: 50%" v-model="form.confirmPassword" type="password" show-password
                    placeholder="8位以上数字+字母"></el-input>
        </el-form-item>
<!--        <el-form-item label="单位名称" prop="businessName">-->
<!--          <el-input style="width: 50%" v-model="form.businessName" placeholder="申请单位名称，与营业执照一致"></el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="营业执照" required>-->
<!--          <el-upload-->
<!--              class="upload-demo"-->
<!--              action="#"-->
<!--              list-type="picture"-->
<!--              :on-change="handleChange"-->
<!--              :on-preview="handlePreview"-->
<!--              :on-remove="handleRemove"-->
<!--              :file-list="fileList"-->
<!--              :auto-upload="false"-->
<!--              :multiple="false"-->
<!--              :limit=" 1"-->
<!--          >-->
<!--            <el-button slot="trigger" size="small" type="primary">上传附件</el-button>-->
<!--            <div slot="tip" class="el-upload__tip">jpg或png图片</div>-->
<!--          </el-upload>-->
<!--        </el-form-item>-->


<!--        <el-form-item>-->
<!--          <el-checkbox v-model="form.agree" style="margin-right: 5px;"></el-checkbox>-->
<!--          <span>我已阅读并同意遵守<el-button type="text">《用户服务协议》</el-button>和<el-button-->
<!--              type="text">《隐私政策》</el-button></span>-->
<!--        </el-form-item>-->
        <el-form-item>
          <el-button type="primary" @click="onSubmit">确认提交</el-button>
        </el-form-item>
      </el-form>
	    
	    <PageComponents/>
    </div>

    <div>
      <el-footer
          style="text-align: center; background-color: #EAEBEB; padding: 10px; bottom: 0; left: 0; width: 100%;">
        <div style="display: flex; align-items: center;justify-content: space-around; width: 80%;margin-left: 10%">
          <div style=" display: flex;align-items: center">
            <div>技术支持：</div>
            <img src="../assets/heibai.png" style="height: 40px" alt="">
          </div>
          <div>
            Copyright © 2014-2024
          </div>
          <div>
	          沪ICP备2022031715号
          </div>
<!--          <div>-->
<!--            赣公网安备 3607012000119号-->
<!--          </div>-->
        </div>

      </el-footer>
    </div>
	  <el-dialog
			  title="验证码"
			  :visible.sync="yanZhengDialog"
			  v-model="yanZhengDialog"
			  :close-on-click-modal="false"
			  width='35%'
			  :center = 'true'
	  >
		  <el-form :model="form" :rules="rules" ref="form" label-position="left" label-width="130px" class="">
			  <div style="display: flex;">
				  <el-form-item prop="QRcode" label="图形验证码">
					  <el-input v-model="form.QRcode" style="width: 200px;" @keyup.enter.native="enterKey" auto-complete="off" placeholder="请先验证图形验证码"></el-input>
				  </el-form-item>
				  <div v-loading="imageLoading">
					  <img :src="codeImage" @click="refreshCode" style="width: 150px;height: 40px;margin-left:20px;" alt="验证码">
				  </div>
			  </div>
		  </el-form>
		  <div slot="footer" class="dialog-footer">
			  <el-button type="primary" @click="sendVerificationCode">确定</el-button>
		  </div>
	  </el-dialog>
  </div>
</template>
<script>
import {
  registerProjectAccount, captchaImage, sendSMSVerificationCode,
  resetProjectAccountPassword
} from '../api/api';
import { validPassword, validPhone } from "../../util/validate";
import ObsClient from "esdk-obs-browserjs/src/obs";
import PageComponents from './XieYi/pagecomponents.vue'
export default {
	components: {
		PageComponents
},
  data () {
    let isPhoneNumberNum = (rule, value, callback) => {
      if (!validPhone(value)) {
        return callback(new Error("请输入正确的手机号"));
      } else {
        callback();
      }
    };
    let isallowPassword = (rule, value, callback) => {
      if (!validPassword(value)) {
        return callback(new Error("至少为8个字符，其中包含数字、大写字母或小写字母"));
      } else {
        callback();
      }
    };

    // 自定义验证器：检查两次输入的密码是否相同
    let isSamePassword = (rule, value, callback) => {
      if (value !== this.form.password) {
        callback(new Error('两次输入的密码不相同'));
      } else {
        callback();
      }
    }

    return {
			yanZhengDialog: false,
      countdown: 0,
      rules: {
        phoneNumber: [
          { required: true, message: '请填写手机号', trigger: 'blur' },
          { validator: isPhoneNumberNum, trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { validator: isallowPassword, trigger: 'blur' },
        ],
        confirmPassword: [
          { required: true, message: '请再次输入密码', trigger: 'blur' },
          { validator: isallowPassword, trigger: 'blur' },
          { validator: isSamePassword, trigger: 'blur' }, // 添加这一行
        ],
        // businessName: [
        //   { required: true, message: '请输入单位名称', trigger: 'blur' },
        // ],
        // name: [
        //   { required: true, message: '请输入姓名', trigger: 'blur' },
        // ],
        code: [
          { required: true, message: '请输入短信验证码', trigger: 'blur' },
        ],
        QRcode: [
          { required: true, message: '请输入图形验证码', trigger: 'blur' },
        ],
      },
      form: {
        phoneNumber: undefined,
        code: '',
        businessName: undefined,
        businessLicense: undefined,
        name: undefined,
        password: '',
        confirmPassword: '',
        agree: false,
        QRcode: '',
      },
      imageLoading: false,
      codeImage: '',
      fileList: [],
      isAdd: false,
	    Bucket: process.env.VUE_APP_BUCKET_NAME,  // 存储桶名称
	    obsClient: null,  // 初始化OBS客户端
	    uploading: false, // 上传状态
	    Area: process.env.VUE_APP_SERVER_ACCESS_AREA_A,    // 区域名称
	    keyValue:'',
	    drawingUrl:'',
	    vLoading: false,
    };
  },
	methods: {
		yanZheng(){
			this.refreshCode()
			this.yanZhengDialog = true;
		},
		// 上传营业执照
		handleFileUpload(event) {
			if (!this.vLoading){
				this.vLoading = true;
			console.log(event,'event')
			const file = event.raw // 获取选择的文件
			console.log(file,'file')
			if (file) {
				const Key = 'businessLicense'+'/'+this.form.phoneNumber+'/'+ file.name; // 使用文件名作为上传的Key
				this.uploadFile(file, Key); // 调用上传方法，并传递额外参数 this.uploadFile(file, Key, additionalData); // 调用上传方法，并传递额外参数
				console.log(Key,'Key')
			}
			}else {
				this.$message({
					type: 'error',
					message: '正在上传中，请稍后重试！',
				});
			}
		},
		// 上传文件方法
		uploadFile(file, Key) {
			this.obsClient = new ObsClient({
				// 这里配置你的OBS客户端参数
				access_key_id: process.env.VUE_APP_ACCESS_KEY_ID,//测试
				secret_access_key: process.env.VUE_APP_SECRET_ACCESS_KEY,//
				server: process.env.VUE_APP_SERVER_ACCESS_AREA
			});
			console.log(this.obsClient,'obsClient')
			this.form.businessLicense =Key;
			this.uploading = true; // 设置上传状态为true
			this.obsClient.putObject({
				Bucket: this.Bucket,
				Key,
				SourceFile: file,
			}, (err, result) => {
				this.uploading = false; // 设置上传状态为false
				if (err) {
					console.error('上传出错:', err); // 打印错误信息
				} else {
					console.log('上传成功:', result); // 打印上传结果
					const url = `https://${this.Bucket}.obs.${this.Area}/${Key}`;
					this.vLoading = false
					console.log('文件URL:', url); // 打印文件URL
				}
			});
		},
		
		
		// 刷新验证码
		refreshCode() {
			this.getQRcodeFun();
		},
		// 获取图形验证码
		getQRcodeFun() {
			var params = {}
			this.imageLoading = true
			captchaImage(params).then(res => {
				let result = res
				if(result.success) {
					this.codeImage = "data:image/jpg;base64," + result.response.img
					this.uuid = result.response.uuid
					this.imageLoading = false
				}
			})
		},
		handleChange(file, fileList) {
			this.fileList = fileList.slice(-3);
		},
		handlePreview(file) {
			console.log(file,'handlePreview');
		},
		handleRemove(file, fileList) {
			console.log('this.fileList', fileList);
		},
		sendVerificationCode() {
			this.yanZhengDialog = false;
			if (!this.form.phoneNumber) {
				this.$message.error('请输入手机号！');
				return;
			}
			if (!this.form.QRcode) {
				this.$message.error('请输入图形验证码！');
				return;
			}
			let params = {
				phoneNumber: this.form.phoneNumber,
				code:this.form.QRcode,
				secretData:undefined,//
				sendType:5,//发送用途类型 0：登陆 1：注册 3：修改密码 4：通知 5：学员考生考试账号注册 6:学员考生解绑手机 7:学员考生忘记密码
				uuid:this.uuid,//
			};
			sendSMSVerificationCode(params).then(res => {
				if (res.data.success){
					this.$message({
						message: res.data.message,
						type: 'success'
					})
				}else {
					this.$message.error(res.data.message);
				}
			})
			this.countdown = 60;
			const interval = setInterval(() => {
				this.countdown--;
				if (this.countdown === 0) {
					clearInterval(interval);
				}
			}, 1000);
		},
		onSubmit() {
			// if (!this.form.agree){
			//   this.$confirm('确认勾选该协议吗？', '提示', {
			//     confirmButtonText: '确认',
			//     cancelButtonText: '取消',
			//     type: 'warning'
			//   }).then(() => {
			console.log(this.fileList,'fileList1111');
			this.form.agree = true ;
			// 用户点击了确认按钮，执行这里的代码
			this.$refs.form.validate((valid) => {
				if (valid) {
					this.fileList.forEach(item => {
						this.handleFileUpload(item)
					})
					console.log(this.fileList, 'this.fileList')
					setTimeout(() => {
						let params = {
							// businessName: this.form.businessName,//单位名称
							// businessLicense: this.form.businessLicense,//营业执照
							code: this.form.code,//短信验证码
							loginPassword: this.form.password,//登录密码
							// name: this.form.name,//姓名
							phoneNumber: this.form.phoneNumber,//手机号
						}
						// this.$router.push({
						// 	path: '/login',
						// });
						
						resetProjectAccountPassword(params).then(res => {
							if (res.data.success) {
								this.$message.success('重置密码成功！');
								this.$router.push({
									path: '/login',
								});
							}else {
								this.$message.error(res.data.message);
							}
						});
					},1000)
					
				} else {
					console.log('error submit!!');
					return false;
				}
			});
			console.log('用户确认删除');
			//   }).catch(() => {
			//     // 用户点击了取消按钮，执行这里的代码
			//     console.log('用户取消删除');
			//   });
			//
			// }
			
		},
	},
  mounted () {
    this.getQRcodeFun();
  }
};
</script>

<style scoped>
.headerContainer {
  display: flex;
  align-items: center;
  margin: 10px 0 10px 10%;
  justify-content: left;
}

.parent {
  height: 70px;
  background-color: #4392F4;
  display: flex;
  justify-content: left;
  align-items: flex-end;
  margin-bottom: 20px;
}

.child {
  margin-left: 10%;
  width: 200px;
  height: 40px;
  background-color: white;
  display: flex;
  justify-content: left;
  align-items: flex-end;
}

.form {
  width: 400px;
  margin: 0 auto;
}

</style>
